"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { LoginSchema } from "@/schema/LoginSchema";
import toast from "react-hot-toast";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm, useFormState } from "react-hook-form";

import { useCallback } from "react";
import { getProviders, signIn } from "next-auth/react";

export function LoginForm({
  csrfToken,
  providers,
}: {
  csrfToken?: string;
  providers: Awaited<ReturnType<typeof getProviders>>;
}) {
  // 1. Define your form.
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
    },
  });
  const { isSubmitting, isValid, isSubmitSuccessful } = useFormState({
    control: form.control,
  });

  // 2. Define a submit handler.
  const onSubmit = useCallback(async (values: z.infer<typeof LoginSchema>) => {
    try {
      await signIn("email", { email: values.email });
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  }, []);

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-sans">Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="hello@world.com"
                    type="email"
                    {...field}
                  />
                </FormControl>
                <FormDescription>
                  This is your personal email address. You must be able to
                  access your email address to login.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            disabled={!isValid || isSubmitting || isSubmitSuccessful}
            type="submit"
            className="bg-blue-500 hover:bg-blue-700"
          >
            {isSubmitting ? "Signing in..." : "Sign in"}
          </Button>
        </form>
      </Form>
      {providers?.github && (
        <div className="space-y-8 mt-8">
          <hr className="w-full" />
          <div className="flex items-center justify-center w-full ">
            <button
              className="bg-slate-900 hover:bg-slate-700 text-white py-2 px-16 rounded-md"
              onClick={() => signIn(providers.github.id)}
            >
              Sign in with {providers.github.name}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
