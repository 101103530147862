import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import BookmarkSquareIcon from "@/components/icons/BookmarkSquareOutlineIcon";
import UserPlusIcon from "@/components/icons/UserPlusOutlineIcon";
import ArrowLeftOnRectangleIcon from "@/components/icons/ArrowLeftOnRectangleOutlineIcon";
import HomeIcon from "@/components/icons/HomeOutlineIcon";
import { useSession, signOut } from "next-auth/react";
import NextLink from "next/link";

const userSolutions = [
  {
    name: "Your Dashboard",
    description: "A homepage for your user account",
    href: "/dashboard",
    icon: HomeIcon,
  },
  {
    name: "Saved Posts",
    description: "See any posts that you have saved",
    href: "/dashboard/saved-posts",
    icon: BookmarkSquareIcon,
  },
];
const adminSolutions = [
  ...userSolutions,
  {
    name: "Admin",
    description: "View and manage the content",
    href: "/admin",
    icon: UserPlusIcon,
  },
];

export function FlyoutStackedWithFooterActions({ children }) {
  const session = useSession();
  const solutions =
    session.data?.user?.role === "ADMIN" ? adminSolutions : userSolutions;

  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        {children}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-[92%] px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {solutions.map((item) => (
                <div
                  key={item.name}
                  className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <item.icon
                      className="h-6 w-6 text-gray-600 group-hover:text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <Popover.Button
                      as={NextLink}
                      href={item.href}
                      className="font-semibold text-gray-900"
                    >
                      {item.name}
                      <span className="absolute inset-0" />
                    </Popover.Button>
                    <p className="mt-1 text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-gray-50">
              <button
                onClick={() =>
                  signOut({
                    callbackUrl: `${window.location.origin}`,
                  })
                }
                className="flex w-full items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
              >
                <ArrowLeftOnRectangleIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
                Sign out
              </button>
              {/* {callsToAction.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                >
                  <item.icon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                  {item.name}
                </a>
              ))} */}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
