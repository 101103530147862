"use client";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useSession, signOut } from "next-auth/react";
import Bars3Icon from "@/components/icons/Bars3Icon";
import XMarkIcon from "@/components/icons/XMarkIcon";
import { PlaceholderUserAvatar } from "@/components/PlaceholderUserAvatar";
import NextLink from "next/link";
import Image from "next/image";
import { useRouter, usePathname } from "next/navigation";
import { FlyoutStackedWithFooterActions } from "@/components/FlyoutStackedWithFooterActions";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { resolveLangPath } from "@/components/LangPicker/helpers";
import { FlyoutSignIn } from "./FlyoutSignIn";

const navigation = [
  { name: "Posts", href: "/posts" },
  { name: "Tags", href: "/tags" },
];

export default function LeftAlignedNavHeader({ lng, csrfToken, providers }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const session = useSession();
  const router = useRouter();
  const pathname = usePathname();

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex items-center gap-x-12">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">melbcoffee.com</span>
            <Image
              src="/melbcoffee-croissant.svg"
              alt="melbcoffee.com croissant logo"
              width={8}
              height={8}
              className="h-8 w-auto"
            />
          </a>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => {
              return (
                <NextLink
                  key={item.name}
                  href={lng === "jp" ? `/jp${item.href}` : item.href}
                  className="text-sm font-semibold leading-6 text-gray-900"
                >
                  {item.name}
                </NextLink>
              );
            })}
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex items-center justify-cetner gap-4">
          <Select
            onValueChange={(value) => {
              if (value !== "en" && value !== "jp") {
                return;
              }

              router.push(resolveLangPath(value, pathname));
            }}
          >
            <SelectTrigger className="w-[110px]">
              <SelectValue
                placeholder={pathname.includes("/jp") ? "日本語" : "English"}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en" className="flex items-center">
                English
              </SelectItem>
              <SelectItem value="jp" className="flex items-center">
                日本語
              </SelectItem>
            </SelectContent>
          </Select>

          {/* {session?.data?.user.role === "ADMIN" && (
            <NextLink
              className="text-blue-500 hover:text-blue-700"
              href="/admin"
            >
              Admin
            </NextLink>
          )} */}
          {session?.data?.user && (
            <FlyoutStackedWithFooterActions>
              <PlaceholderUserAvatar />
            </FlyoutStackedWithFooterActions>
          )}
          {!session.data?.user && (
            <FlyoutSignIn csrfToken={csrfToken?.value} providers={providers}>
              <p className="text-sm font-semibold leading-6 text-gray-900">
                Log in <span aria-hidden="true">&rarr;</span>
              </p>
            </FlyoutSignIn>
          )}
        </div>
      </nav>
      {/* <Transition
        show={mobileMenuOpen}
        as={Fragment}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      > */}
      <Dialog
        as="div"
        open={mobileMenuOpen}
        className="lg:hidden"
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <NextLink href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">melbcoffee.com</span>
              <Image
                src="/melbcoffee-croissant.svg"
                alt="melbcoffee.com croissant logo"
                width={8}
                height={8}
                className="h-8 w-auto"
              />
            </NextLink>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => {
                  if (item.name === "Saved" && !session?.data?.user) {
                    return null;
                  }

                  return (
                    <NextLink
                      key={item.name}
                      href={lng === "jp" ? `/jp${item.href}` : item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      onClick={() => {
                        setMobileMenuOpen(false);
                      }}
                    >
                      {item.name}
                    </NextLink>
                  );
                })}
              </div>
              <div className="py-6 space-y-2">
                {session?.data?.user.role === "ADMIN" && (
                  <NextLink
                    className="text-blue-500 hover:text-blue-700"
                    href="/admin"
                  >
                    Admin
                  </NextLink>
                )}
                {!session.data?.user && (
                  <NextLink
                    href="/login"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </NextLink>
                )}
                {session.data?.user && (
                  <button
                    onClick={() =>
                      signOut({
                        callbackUrl: `${window.location.origin}`,
                      })
                    }
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Sign out
                  </button>
                )}
              </div>
              <div className="py-6">
                <Select
                  onValueChange={(value) => {
                    if (value !== "en" && value !== "jp") {
                      return;
                    }

                    router.push(resolveLangPath(value, pathname));
                  }}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue
                      placeholder={
                        pathname.includes("/jp") ? "日本語" : "English"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="en" className="flex items-center">
                      English
                    </SelectItem>
                    <SelectItem value="jp" className="flex items-center">
                      日本語
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
      {/* </Transition> */}
    </header>
  );
}
