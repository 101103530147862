"use client";
import { atom, useAtom } from "jotai";
import { createId as cuid } from "@paralleldrive/cuid2";

export const clientIdAtom = atom<string | null>(null);

function useHydrateClientId() {
  const [_, setClientId] = useAtom(clientIdAtom);

  if (typeof window !== "undefined") {
    const storedClientId = localStorage.getItem("clientId");

    const clientId = storedClientId || cuid();
    setClientId(clientId);

    if (!storedClientId) {
      localStorage.setItem("clientId", clientId);
    }
  }
}

export const HydrateClientIdAtom = ({ children }) => {
  // initialising on state with prop on render here
  useHydrateClientId();
  return children;
};
