import { i18n, Locale } from "@/utils/i18n";

export function mapLangToEmoji(lang: Locale) {
  switch (lang) {
    case "jp":
      return "🇯🇵";
    case "en":
    default:
      return "🇬🇧";
  }
}

export function resolveLangPath(lang: Locale, pathname: string) {
  // If the path has a language prefix, remove it and return the rest of the path
  // with the new language prefix. For English, remove it and return the rest of
  // the path without a language prefix.
  const [, langPrefix, ...rest] = pathname.split("/");
  const hrefSuffix = rest.join("/");

  if (i18n.locales.includes(langPrefix as Locale)) {
    if (lang === "en") {
      return `/${hrefSuffix}`;
    } else {
      return pathname.replace(langPrefix, lang);
    }
  }

  return lang === "en"
    ? `/${langPrefix}/${hrefSuffix}`
    : `/${lang}/${langPrefix}/${hrefSuffix}`;
}

export function getLocales() {
  return i18n.locales;
}
