import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useSession } from "next-auth/react";
import { LoginForm } from "@/components/form/LoginForm";

export function FlyoutSignIn({ children, csrfToken, providers }) {
  const session = useSession();

  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        {children}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-[92%] px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              <LoginForm csrfToken={csrfToken?.value} providers={providers} />
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
